import { useContext } from 'react'
import ProductShelfSkeleton from 'src/components/skeletons/ProductShelfSkeleton'

import { RecommendationContext } from '../../contexts/recommendations-context'
import type {
  DataRecommendation,
  Showcases,
} from '../../types/linx-recommendations'
import ShelfV2 from '../ShelfV2/ShelfV2'

type GenericShelfV2Props = {
  position: keyof typeof POSITION_MAP
  enableSession?: boolean
  type?: string
  shelfServerData?: DataRecommendation[]
}

export const POSITION_MAP = {
  topo: 'top',
  top: 'top',
  middle: 'middle',
  meio: 'middle',
  baixo: 'bottom',
  bottom: 'bottom',
  'area bonus': 'area-bonus',
  'area-bonus': 'area-bonus',
}

const renderShelves = (showCases: Showcases[], type?: string) =>
  showCases.map(({ id, impressionUrl, title, name, displays }: Showcases) => (
    <ShelfV2
      engine="linx"
      key={id}
      {...{ impressionUrl, title, name }}
      products={displays[0].recommendations}
      type={type}
    />
  ))

const GenericShelfv2 = ({
  position,
  enableSession = true,
  type,
  shelfServerData = [],
}: GenericShelfV2Props) => {
  const { dataRecommendation, error, loading } = useContext(
    RecommendationContext
  )

  if (!enableSession || error) {
    return null
  }

  const shelfData =
    dataRecommendation?.filter(
      (item: DataRecommendation) =>
        item.position === POSITION_MAP[position] && item.showcases.length
    ) || []

  const showCases =
    (dataRecommendation
      ? shelfData[0]?.showcases
      : shelfServerData[0]?.showcases) || []

  if (loading && !shelfServerData.length) {
    return (
      <div className="w-full max-w-[1280px] mx-auto">
        <ProductShelfSkeleton />
      </div>
    )
  }

  return showCases.length ? <div>{renderShelves(showCases, type)}</div> : null
}

export default GenericShelfv2
