type SliderButtonProps = {
  onClick: () => void
  isActive: boolean
  direction: 'left' | 'right'
  style?: 'top' | 'middle'
  clasName?: string
}

export const SliderButton = ({
  onClick,
  isActive,
  direction,
  style = 'top',
  clasName = 'flex items-center justify-center  w-[64px] h-[64px] rounded-round border border-primary',
}: SliderButtonProps) => {
  const icon = {
    top: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.66663 16.0002C5.66663 15.448 6.11434 15.0002 6.66663 15.0002H25.3333C25.8856 15.0002 26.3333 15.448 26.3333 16.0002C26.3333 16.5525 25.8856 17.0002 25.3333 17.0002H6.66663C6.11434 17.0002 5.66663 16.5525 5.66663 16.0002Z"
          fill={isActive ? '#15181B' : '#A4ADB7'}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d={
            direction === 'left'
              ? 'M16.7071 5.95979C17.0976 6.35032 17.0976 6.98348 16.7071 7.37401L8.08084 16.0002L16.7071 24.6265C17.0976 25.017 17.0976 25.6502 16.7071 26.0407C16.3165 26.4312 15.6834 26.4312 15.2929 26.0407L5.95952 16.7073C5.56899 16.3168 5.56899 15.6837 5.95952 15.2931L15.2929 5.95979C15.6834 5.56927 16.3165 5.56927 16.7071 5.95979Z'
              : 'M15.2929 5.95979C15.6834 5.56927 16.3166 5.56927 16.7071 5.95979L26.0404 15.2931C26.431 15.6837 26.431 16.3168 26.0404 16.7073L16.7071 26.0407C16.3166 26.4312 15.6834 26.4312 15.2929 26.0407C14.9024 25.6502 14.9024 25.017 15.2929 24.6265L23.9191 16.0002L15.2929 7.37401C14.9024 6.98348 14.9024 6.35032 15.2929 5.95979Z'
          }
          fill={isActive ? '#15181B' : '#A4ADB7'}
        />
      </svg>
    ),
    middle: (
      <span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="#000"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d={
              direction === 'left'
                ? 'M12.9419 4.55806C13.186 4.80214 13.186 5.19786 12.9419 5.44194L8.38388 10L12.9419 14.5581C13.186 14.8021 13.186 15.1979 12.9419 15.4419C12.6979 15.686 12.3021 15.686 12.0581 15.4419L7.05806 10.4419C6.81398 10.1979 6.81398 9.80214 7.05806 9.55806L12.0581 4.55806C12.3021 4.31398 12.6979 4.31398 12.9419 4.55806Z'
                : 'M7.05806 4.55806C7.30214 4.31398 7.69786 4.31398 7.94194 4.55806L12.9419 9.55806C13.186 9.80214 13.186 10.1979 12.9419 10.4419L7.94194 15.4419C7.69786 15.686 7.30214 15.686 7.05806 15.4419C6.81398 15.1979 6.81398 14.8021 7.05806 14.5581L11.6161 10L7.05806 5.44194C6.81398 5.19786 6.81398 4.80214 7.05806 4.55806Z'
            }
            fill="#000"
          />
        </svg>
      </span>
    ),
  }

  return (
    <button className={`${direction} ${clasName}`} onClick={onClick}>
      {icon[style]}
    </button>
  )
}

export const PrevButton = ({
  onClick,
  isActive,
  style,
  clasName,
}: Omit<SliderButtonProps, 'direction'>) => (
  <SliderButton
    onClick={onClick}
    isActive={isActive}
    direction="left"
    style={style}
    clasName={clasName}
  />
)

export const NextButton = ({
  onClick,
  isActive,
  style,
  clasName,
}: Omit<SliderButtonProps, 'direction'>) => (
  <SliderButton
    onClick={onClick}
    isActive={isActive}
    direction="right"
    style={style}
    clasName={clasName}
  />
)
