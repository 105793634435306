import Skeleton from 'react-loading-skeleton'
import { useMobile } from 'src/hooks/useMobile'
import { useCallback, useRef, useState } from 'react'

import SliderControls from './SliderControls'
import './slider.scss'
import { NextButton, PrevButton } from './SliderButton'

type SliderProps = {
  title?: string
  itemQuantity: number
  skeletonStyles?: string
  hasScrollbar?: boolean
  children?: React.ReactNode
  controlsStyle?: 'top' | 'middle'
}

const Slider = ({
  title,
  itemQuantity,
  skeletonStyles,
  hasScrollbar = true,
  children,
  controlsStyle = 'top',
}: SliderProps) => {
  const { isMobile, screenWidth } = useMobile()
  const listRef = useRef<HTMLUListElement | null>(null)
  const listWrapperRef = useRef<HTMLDivElement | null>(null)
  const thumbRef = useRef<HTMLDivElement>(null)
  const isDraggingRef = useRef(false)
  const initialScrollLeftRef = useRef<number>(0)
  const startXRef = useRef<number>(0)
  const [navState, setNavState] = useState<{
    prev: boolean
    next: boolean
  }>({ prev: false, next: true })

  const [thumbWidth, setThumbWidth] = useState(20)
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [isDragging, setIsDragging] = useState(false)
  const SPEED_MULTIPLIER = -3

  const handleResize = () => {
    if (!listRef.current) {
      return
    }

    const visibleWidth = listRef.current.offsetWidth
    const contentWidth = listRef.current.scrollWidth

    setThumbWidth((visibleWidth / contentWidth) * 100)
  }

  const onScroll = () => {
    if (!listRef?.current || !listWrapperRef?.current) {
      return
    }

    const wrapperWidth = listWrapperRef.current.getBoundingClientRect().width

    const { scrollWidth, scrollLeft } = listRef.current

    setNavState((old) => ({
      ...old,
      prev: scrollLeft !== 0,
      next: scrollWidth - wrapperWidth - scrollLeft !== 0,
    }))

    setCurrentPage(
      itemQuantity -
        Math.round((scrollWidth - scrollLeft) / itemSizeAndQttyToScroll().width)
    )
  }

  const handleThumbMouseMove = useCallback(
    (e: MouseEvent) => {
      if (!isDraggingRef.current || !thumbRef.current || !listRef.current) {
        return
      }

      const deltaX = e.clientX - startXRef.current
      const wrapper = listRef.current
      const scrollWidth = wrapper.scrollWidth - wrapper.offsetWidth

      const newScrollLeft = Math.min(
        Math.max(initialScrollLeftRef.current - deltaX * SPEED_MULTIPLIER, 0),
        scrollWidth
      )

      wrapper.scrollLeft = newScrollLeft
    },
    [listRef, SPEED_MULTIPLIER]
  )

  const handleThumbMouseUp = useCallback(() => {
    if (!thumbRef.current || !listRef.current) {
      return
    }

    isDraggingRef.current = false
    setIsDragging(false)
    document.removeEventListener('mousemove', handleThumbMouseMove)
    document.removeEventListener('mouseup', handleThumbMouseUp)
  }, [handleThumbMouseMove, listRef])

  const handleThumbMouseDown = (e: React.MouseEvent) => {
    e.preventDefault()
    if (!thumbRef.current || !listRef.current) {
      return
    }

    setIsDragging(true)
    isDraggingRef.current = true
    startXRef.current = e.clientX
    initialScrollLeftRef.current = listRef.current.scrollLeft

    document.addEventListener('mousemove', handleThumbMouseMove)
    document.addEventListener('mouseup', handleThumbMouseUp)
  }

  const itemSizeAndQttyToScroll = () => {
    let obj

    if (screenWidth > 1024) {
      obj = { width: 324, qtty: 4 }
    } else if (screenWidth > 768) {
      obj = { width: 266, qtty: 3 }
    } else {
      obj = { width: 166, qtty: 2 }
    }

    return obj
  }

  const moveTo = (index: number) => {
    if (!listRef?.current || !listWrapperRef?.current) {
      return
    }

    listRef.current.scrollLeft = index * itemSizeAndQttyToScroll().width
  }

  const onPrevClick = (): void => {
    if (!listRef?.current || !listWrapperRef?.current) {
      return
    }

    moveTo(currentPage - itemSizeAndQttyToScroll().qtty)
  }

  const onNextClick = (): void => {
    if (!listRef?.current || !listWrapperRef?.current) {
      return
    }

    moveTo(currentPage + itemSizeAndQttyToScroll().qtty)
  }

  return (
    <>
      <div className="flex justify-between items-center px-4 md:px-0">
        <h2 className="flex text-restructure-primary desktop-heading-title3 tablet-heading-title3 mobile-heading-title3">
          {title}
        </h2>

        {!isMobile && itemQuantity > 4 && controlsStyle === 'top' && (
          <SliderControls {...{ onNextClick, onPrevClick, navState }} />
        )}
      </div>

      {itemQuantity > 0 ? (
        <div className="slider-container">
          <div ref={listWrapperRef} className="slider-container relative">
            {controlsStyle === 'middle' && !isMobile && itemQuantity > 2 && (
              <div className="absolute left-2 top-0 h-full cursor-pointer z-10 flex justify-center items-center">
                <PrevButton
                  isActive={navState.prev}
                  onClick={onPrevClick}
                  style={controlsStyle}
                  clasName={`w-[40px] h-[40px] bg-white flex justify-center items-center rounded-round opacity-${
                    navState.prev ? 1 : 50
                  }`}
                />
              </div>
            )}
            <ul
              ref={(element) => {
                listRef.current = element
                handleResize()
              }}
              className="slider-list px-4 md:px-0 "
              onScroll={onScroll}
            >
              {children}
            </ul>
            {controlsStyle === 'middle' && !isMobile && itemQuantity > 2 && (
              <div className="absolute right-2 top-0 h-full cursor-pointer z-10 flex justify-center items-center">
                <NextButton
                  isActive={navState.next}
                  onClick={onNextClick}
                  style={controlsStyle}
                  clasName="w-[40px] h-[40px] bg-white flex justify-center items-center rounded-round"
                />
              </div>
            )}
          </div>
          <div
            className={`slider-scrollbar ${hasScrollbar ? '' : 'hidden'} ${
              thumbWidth === 100 ? 'hidden' : ''
            } `}
            style={{ cursor: isDragging ? 'grabbing' : 'grab' }}
          >
            <div className="slider-scrollbar-track" />
            <div
              onMouseDown={handleThumbMouseDown}
              style={{
                left: listRef.current
                  ? `${
                      (listRef.current.scrollLeft /
                        listRef.current.scrollWidth) *
                      100
                    }%`
                  : '0%',
                width: `${thumbWidth}%`,
              }}
              ref={thumbRef}
              className="slider-scrollbar-thumb"
              tabIndex={0}
              role="slider"
              aria-valuemin={0}
              aria-valuemax={100}
              aria-valuenow={
                listRef.current
                  ? (listRef.current.scrollLeft / listRef.current.scrollWidth) *
                    100
                  : 0
              }
              aria-label="scrollbar thumb"
            />
          </div>
        </div>
      ) : (
        <Skeleton className={skeletonStyles} />
      )}
    </>
  )
}

export default Slider
