import axios from 'axios'
import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { useLinxUser } from 'src/sdk/linx/hooks/useLinxUser'
import { useMobile } from 'src/hooks/useMobile'
import type { RecommendationsParams } from 'src/utils/recommendations/getShelfServerSideData'

import type { Product } from '../types/product'

type PageName = 'product' | 'home' | 'emptysearch' | 'cart'

type RecommendationProviderProps = {
  children: React.ReactNode
  pageName: PageName
  product?: Product
}

type RecommendationContextProps = {
  dataRecommendation: any
  loading: boolean
  error: boolean
}

export const RecommendationContext = createContext<RecommendationContextProps>(
  {} as RecommendationContextProps
)

export const RecommendationProvider = ({
  children,
  product,
  pageName,
}: RecommendationProviderProps) => {
  const productId = product?.productId
  const categories = product?.categoriesIds[0]
    .split('/')
    .filter((category) => category !== '')
    .map((category) => `categoryId=${category}`)
    .join('&')

  const { getLinxUser } = useLinxUser()
  const [dataRecommendation, setDataRecommendation] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  const { isMobile } = useMobile()

  useEffect(() => {
    if (isMobile === undefined) return

    const linxUser = getLinxUser()

    const getRecommendations = async () => {
      try {
        setLoading(false)

        let params: RecommendationsParams = {
          ...(productId && { productId }),
          deviceId: linxUser.deviceId,
          source: isMobile ? 'mobile' : 'desktop',
          name: pageName,
        }

        if (linxUser.loggedIn) {
          params = { ...params, userId: (linxUser as { id: string }).id }
        }

        const { data: response } = await axios.get(
          `https://decathlonstore.myvtex.com/_v3/api/decathlon-search/pages/recommendations?${categories}`,
          {
            params,
          }
        )

        setDataRecommendation(response)
        setLoading(false)
      } catch (e) {
        console.error(e)
        setError(true)
        setLoading(false)
      }
    }

    getRecommendations()
  }, [getLinxUser, productId, pageName, isMobile, categories])

  const recommendationContextValue = useMemo(
    () => ({
      product,
      dataRecommendation,
      loading,
      error,
    }),
    [product, dataRecommendation, loading, error]
  )

  return (
    <RecommendationContext.Provider value={recommendationContextValue}>
      {children}
    </RecommendationContext.Provider>
  )
}

export const useRecommendationContext = () => useContext(RecommendationContext)
