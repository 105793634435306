import { PrevButton, NextButton } from './SliderButton'

type SliderControlsProps = {
  onPrevClick: () => void
  onNextClick: () => void
  navState: {
    prev: boolean
    next: boolean
  }
}

const SliderControls = ({
  onNextClick,
  onPrevClick,
  navState,
}: SliderControlsProps) => {
  return (
    <div className="flex gap-6">
      <PrevButton onClick={onPrevClick} isActive={navState.prev} />
      <NextButton onClick={onNextClick} isActive={navState.next} />
    </div>
  )
}

export default SliderControls
